import React, { createContext, useState } from "react";

export const MonthlyAlert = createContext();
const MonthlyAlertContextProvider = (props) => {
    const [showAlertModal, changeItems] = useState(false);
    const changeState = (val) => {
        changeItems(val);
    };
    return (
        <MonthlyAlert.Provider value={{ showAlertModal, changeState }}>
            {props.children}
        </MonthlyAlert.Provider>
    );
};
export default MonthlyAlertContextProvider;