import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner';
import ChangeInClientStatus from './ChangeInClientStatus';

const ChangeInReqTable=({setShowForm})=> {
    const [ChangeInReqdata,setChangeInReqData]=useState([])
    const [allChangeInReqData,setallChangeInReqData]=useState([])
const [edit,setedit]=useState(false)
  const [loader1, setloader1] = useState(false);
  const [filterCientStatus,setfilterClientStatus]=useState([])

  const [showUpdateForm,setShowupdateForm]=useState(
    "Table",
    "ClientForm",
    "AmendStatus"
)
const handleClientStatus=(changereqData)=>{
    setfilterClientStatus(changereqData)
    }
  const getdata = () => {
    axios
      .get(`http://internalhrapi.aiksol.com/api/getchangereqs`)
      .then(function (response) {
        // console.log(response.data.Data)

        let arr=[]
        response.data.Data.forEach((changeReqdata,ind)=>{
    let amendmentStatus  =  changeReqdata?.Amendement?.find((amend)=>{
        return amend?.amend_status === "pending"
      })
    return arr.push({
        id: ind + 1,
        description: changeReqdata?.description,
        priority: changeReqdata?.priority,
        company_status: changeReqdata?.company_status,
        client_status: changeReqdata?.client_status,
        google_docs: changeReqdata?.google_docs,
        project_name: changeReqdata?.project,
        module_name: changeReqdata?.module,
        submodule_name: changeReqdata?.submodule,
        request_id: changeReqdata?.request_id,
        req_timeline: changeReqdata?.req_timeline,
        Amendement: changeReqdata?.Amendement,
        AmendStatus:changeReqdata?.Amendement?.length > 0 ? amendmentStatus ? "pending" : "completed" : "" 
    })
    

})
setallChangeInReqData(arr)
        setChangeInReqData(arr)
})
      .catch((err) => {
        console.log(err);
      });
  };



  useEffect(()=>{
    getdata()
  },[])

  const onTextChange = () => {
    if (
      document.getElementById("filterbyPriorty").value === "" &&
      document.getElementById("filterbyCompanyStatus").value === "" &&
      document.getElementById("filterbyClientStatus").value === "" &&
      document.getElementById("filterbyModule").value === "" &&
      document.getElementById("filterbySubModule").value === "" 
    ) {
      setallChangeInReqData(ChangeInReqdata);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("filterbyPriorty").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("filterbyCompanyStatus").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("filterbyClientStatus").value,
          "i"
        );  const regex3 = new RegExp(
          document.getElementById("filterbyModule").value,
          "i"
        );  const regex4 = new RegExp(
          document.getElementById("filterbySubModule").value,
          "i"
        );
       
       

        suggestions = ChangeInReqdata
          .sort()
          .filter(
            (val) =>
              (document.getElementById("filterbyPriorty").value === "" ||
                regex.test(val?.priority)) &&
              (document.getElementById("filterbyCompanyStatus").value === "" ||
                regex1.test(val?.company_status)) 
                &&
              (document.getElementById("filterbyClientStatus").value === "" ||
                regex2.test(val?.client_status)) 
                &&
                (document.getElementById("filterbyModule").value === "" ||
                  regex3.test(val?.module_name)) 
                  &&
                  (document.getElementById("filterbySubModule").value === "" ||
                    regex4.test(val?.submodule_name)) 
          );
      }
      setallChangeInReqData(suggestions);
    }
  };

  return (
    <>


{ showUpdateForm==="Table"
&&



<div className="SettingContainer1 mt-3 Sitk dataTablecsSales height90">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="row row1_style input_border " style={{padding:"10px 10px 0px 10px"}}>
            {/* <div className="col-12"> */}
                <div style={{width: "90%",display:"flex",gap:"10px",flexWrap:"wrap"}}>
             
                {" "}
                <input
                style={{width:"150px",height:"30px"}}
                id="filterbyPriorty"
                  type="text"
                  onChange={onTextChange}
                  autoComplete="off"
                  placeholder="Priority"
                  className="input_border"
                ></input>
                <input
                style={{width:"150px",height:"30px"}}

                  type="text"
                id="filterbyCompanyStatus"
                  onChange={onTextChange}
                  autoComplete="off"
                  placeholder="CompanyStatus"
                  className="input_border "
                ></input>
                                <input
                style={{width:"150px",height:"30px"}}

                  type="text"
                id="filterbyClientStatus"
                  onChange={onTextChange}
                  autoComplete="off"
                  placeholder="Client Status"
                  className="input_border"
                ></input>
                                <input
                style={{width:"150px",height:"30px"}}

                  type="text"
                id="filterbyModule"
                  onChange={onTextChange}
                  autoComplete="off"
                  placeholder="Module"
                  className="input_border"
                ></input>
                                <input
                style={{width:"150px",height:"30px"}}

                  type="text"
                id="filterbySubModule"
                  onChange={onTextChange}
                  autoComplete="off"
                  placeholder="SubModule"
                  className="input_border"
                ></input>
           
       
              </div>

           
            
                {/* <span className="ml-2 float-right" style={{width:"10%"}}> */}
                    <div style={{width:"10%"}}>
                  <button
                    onClick={() => {
                        setShowForm(true)
                    //   setbuyer(null);
                    //   setshowForm(true);
                    }}
                    className="tabletopbutton1 text-right"
                  >
                    New
                  </button>
                  </div>
                {/* </span> */}
             
              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            {/* </div> */}
          </div>
          <div className="row" style={{maxHeight:"450px"}}>
            <table class="table table-hover tableCash "  >
              <thead class="purple whiteText">
                <tr>
                <th scope="col" className="th1">
                    Sr.No
                  </th>
                  <th scope="col" className="th1">
                    Description
                  </th>
                  <th scope="col" className="th1">
                    Priority
                  </th>
                  <th scope="col" className="th1">
                    Company Status
                  </th>
                  <th scope="col" className="th1">
                    Client Status
                  </th>
                  <th scope="col" className="th1">
                    Googlr Docs
                  </th>
                  <th scope="col" className="th1">
                    Module
                  </th>
                  <th scope="col" className="th1">
                    SubModule
                  </th>
                  <th scope="col" className="th1">
                    Amend Status
                  </th>
                  <th scope="col" className="th1">
                    Update Status
                  </th>
                </tr>
              </thead>
              <tbody >
                {allChangeInReqData.map((changereqData) => {

                return (  <tr
                    className="trpointer"
                    onClick={() => {
                    //   openmodaljournaltable(val);
                    }}
                  >
                    {/* <th className="th2">
                      {i +
                        1 +
                        (paginationdata.pageNumber - 1) *
                          paginationdata.pageSize}
                    </th> */}
                   <td className="th2 tdcell">{changereqData.id}</td>
                   <td className="th2 tdcell">{changereqData?.description}</td>
    <td className="th2 tdcell">{changereqData?.priority}</td>
    <td className="th2 tdcell">{changereqData?.company_status}</td>
    <td className="th2 tdcell">{changereqData?.client_status}</td>
    <td className="th2 tdcell">{changereqData?.google_docs}</td>
    <td className="th2 tdcell">{changereqData?.module_name}</td>
    <td className="th2 tdcell">{changereqData?.submodule_name}</td>
    <td className="th2 tdcell">{changereqData?.AmendStatus}</td>
    <td className="th2 tdcell">{ changereqData?.client_status === "Fresh" ? 
     changereqData?.company_status === "completed"   &&   changereqData?.AmendStatus !== "pending"   ? <button type="button" class="btn btn-primary" style={{fontSize:"10px",height:"25px",alignItems:"center"}} onClick={()=>{setShowupdateForm("ClientForm");handleClientStatus(changereqData)}}>Update</button> :  "" :  <button type="button" class="btn btn-primary" style={{fontSize:"10px",height:"25px",alignItems:"center",backgroundColor:"green"}} onClick={()=>{setShowupdateForm("ClientForm");handleClientStatus(changereqData); setedit(true)}}>See Details</button> }
    
    
    </td>

                    {/* <td title={val.clientName} className="th2 tdcell">
                      {val.clientName}
                    </td> */}
                    {/* <td title={val.clientCell} className="th2 tdcell">
                      {val.clientCell}
                    </td>
                    <td title={val.clientCnic} className="th2 tdcell">
                      {val.clientCnic}
                    </td>
                    <td title={val.clientCity} className="th2 tdcell">
                      {val.clientCity}
                    </td>
                    <td title={val.clientAddress} className="th2 tdcell">
                      {val.clientAddress}
                    </td>
                    <td
                      onClick={() => {
                        setbuyer(val);
                        setshowForm(true);
                      }}
                    >
                      <FiEdit />
                    </td> */}
                  </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          {/* <div className="row  mt-3 pb-2">
            <div className="col-12 text-right">
              <span>
                <button
                  onClick={() => {
                    // getNextdata(firstPage);
                  }}
                  className="tabletopbutton text-right ml-2 mr-2"
                >
                  First
                </button>
              </span>
              <span>
                <button
                  onClick={() => {
                    // getNextdata(nextPage);
                  }}
                  className="tabletopbutton  text-right mr-2"
                >
                  Next
                </button>
              </span>
              <span>
                <button
                  onClick={() => {
                    // getNextdata(previousPage);
                  }}
                  className="tabletopbutton  text-right mr-2"
                >
                  Previous
                </button>
              </span>
              <span>
                <button
                  onClick={() => {
                    // getNextdata(lastPage);
                  }}
                  className="tabletopbutton  text-right mr-2"
                >
                  Last
                </button>
              </span>
            </div>
          </div> */}
        </div>
      </div>
}

{
        showUpdateForm==="ClientForm" &&
        (<ChangeInClientStatus getdata={getdata} filterCientStatus={filterCientStatus} edit={edit} setedit={setedit}  setShowupdateForm={setShowupdateForm}/>)
    }
</>
  )
}

export default ChangeInReqTable;