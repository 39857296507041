import axios from "axios";
import Cookies from "universal-cookie";
const cookies=new Cookies()
export const GetAllExpenceTags = (data) => {
  console.log(data);
  return {
    type: "GET_ALL_EXPENCE_TAGS",
    payload: data,
    loader: false,
  };
};

export const getAllExpenceTags = () => {
  return (dispatch) => {
    axios({
      url: `${
        window.$url
      }/PaymentVoucher/GetExpenseTags?ProjectId=${cookies.get(
        "ProjectId"
      )}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${cookies.get("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        dispatch(GetAllExpenceTags(result.data?.Data));
      },
      (error) => {}
    );
  };
};
