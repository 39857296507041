const initialState = { ExpenceTags: [], loading: true };

export const ExpenceTagReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_EXPENCE_TAGS":
      return {
        ExpenceTags: action.payload,
        loading: action.loader,
      };
    default:
      return state;
  }
};
