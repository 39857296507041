const getalldevcostreceipt = {data: [], loading: true};

export const GetAllDecCostReceiptReducer = (state = getalldevcostreceipt, action) =>{
    switch(action.type){
        case 'getalldevcostreceipt':
            return{
                data:action.payload,
                loading:action.loader
            }
        default:
            return state;
    }
}