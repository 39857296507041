import React from "react";
import ExpenceTagForm from "./ExpenceTagForm";
import ExpenceTagTable from "./ExpenceTagTable";

const ExpenceTagsMain = () => {
  return (
    <div
      style={{
        display: "flex  ",
        gap: "10px",
        flexDirection: "column",
      }}
    >
      <ExpenceTagForm />
      <ExpenceTagTable />
    </div>
  );
};

export default ExpenceTagsMain;
