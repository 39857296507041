import { combineReducers } from "redux";
import { ExpenceTagReducer } from "./ExpenceTagsReducer";
import { GetAllDecBankReceiptReducer } from "./getAllDevCostBankReceiptReducer";
import { GetAllDecCostReceiptReducer } from "./getallDevCostCashReceiptReducer";

import { GetAllPopulateContractsDataReducer } from "./getcontractreducer";
import { GetAllPopulateDevCostDataReducer } from "./getPopulateDevCostReducer";

const AllReducers = combineReducers({
  GetAllPopulateContractsDataReducer: GetAllPopulateContractsDataReducer,
  GetAllPopulateDevCostDataReducer: GetAllPopulateDevCostDataReducer,
  GetAllDecCostReceiptReducer: GetAllDecCostReceiptReducer,
  GetAllDecBankReceiptReducer: GetAllDecBankReceiptReducer,
  ExpenceTagReducer: ExpenceTagReducer,
});

export default AllReducers;
