import React, { useState, useEffect } from "react";
import "./haroon.css";
import "../App.css";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import logologin from "../Images/Asset.png";
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import axios from "axios";
import ActivityIndicator from "react-activity-indicator";
import { GoEye } from "react-icons/go";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
const Login = () => {
  const [displayloader, setdisplayloader] = useState(false);
  const [passwordvisible, setpasswordvisible] = React.useState(false);
  const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);


  const cookies = new Cookies();
  let history = useHistory();

  if (cookies.get("Token")) {
    history.push({
      pathname: "/Dashboard",
    });
  }

  const forgotPassword = () => {
    toast.info("Your request has been sent!");
  };
  function handleKeyPress(event) {
    if (event.key === "Enter") {
      signin();
    }
  }
  const signin = () => {
    setdisplayloader(true);
    var USERNAME = document.getElementById("username").value;
    var PASSWORD = document.getElementById("password").value;
    var data = { UserName: USERNAME, Password: PASSWORD };
    axios({
      method: "post",
      url: window.$url + `/Auth/Login`,
      data: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        setdisplayloader(false);
        if (result.data.isSuccess) {
          cookies.set("Token", result.data.token, {
            path: "/",
            expires: new Date(tomorrow),
            maxAge: 10000,
          })
          cookies.set("Extras", btoa(PASSWORD), {
            path: "/",
            expires: new Date(tomorrow),
            maxAge: 10000,
          })
          // localStorage.setItem("Token", result.data.token);
          // localStorage.setItem("Extras", btoa(PASSWORD));
          history.push({ pathname: "/ProjectSelection" });
        } else {
          toast.info(result.data.message);
        }
      },
      //badrequest error result
      (error) => {
        setdisplayloader(false);
        toast.info(error);
      }
    );
  };
  return (
    <div className=" login_div">
      <div className="row w-100">
        <div className="loginbox1">
          <div className="col-12">
            {" "}
            <img src={logologin} alt="logo" className="loginlogo" />
          </div>
          {/* <div className="col-12">
            <div>
              <input type="text" placeholder="Username" className="logininput"/>
            </div>
            <div>
              <input type="password" placeholder="Password" className="logininput"/>
              <GoEye
                onClick={() => {
                  setpasswordvisible(!passwordvisible);
                }}
                size={"1em"}
                id="togglePassword"
                style={{ marginLeft: "-20px" }}
              />
            </div>
          </div> */}
          <div className="col-10 ml-4  mt-1">
            <input
              type="text"
              className="logininput "
              id="username"
              placeholder="username"
            />
            {/* <span className="loginicon ">
              <FaUser className="loginface" />
            </span> */}
          </div>
          <div className="col-10  ml-4  mt-3">
            <input
              type={passwordvisible ? "text" : "password"}
              className="logininput "
              id="password"
              placeholder="password"
              onKeyPress={handleKeyPress}
            />
            <GoEye
              onClick={() => {
                setpasswordvisible(!passwordvisible);
              }}
              size={"1em"}
              id="togglePassword"
              style={{ marginLeft: "-20px" }}
            />

            {/* <span className="loginicon ">
              <FaLock className="loginpass" />
            </span> */}
          </div>
          {/* <div className="col-12 text-right">
                        <span onClick={forgotPassword} className="password">forgot password?</span>
                    </div> */}

          <button
            onClick={signin}
            className="col-12 loginbutton mt-5 mb-3 p-2 signin"
          >
            {displayloader ? (
              <Loader type="Oval" color="white" height={15} width={15} />
            ) : (
              "Log In"
            )}
          </button>
          {/* <Link to="/Dashboard"></Link> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
