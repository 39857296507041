import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Loader from 'react-loader-spinner';
import { toast, ToastContainer } from "react-toastify";
// import  "../../haroon.css"
import "../haroon.css"



const ChangeinReqForm=({setShowForm})=> {
    const [loader1, setloader1] = useState(false);
    const [userdata, setuserdata] = useState({ 
        google_docs:"",
        description:"",
        priority:"",
        project:"",
        module:"",
        submodule:""
       });
       const [allProjects,setAllProjects]=useState([])
       const [Module,setModule]=useState([])
       const [ProjectSubModule,setProjectSubModule]=useState([])
       const objectFormation = (e) => {
           // const { name, value } = e.target;
           setuserdata({
               ...userdata,
               [e.target.name]:e.target.value,
           });
         };

         const addUserData = (e) => {
            e.preventDefault()  
            let size = Object.keys(userdata).length;
            if (size < 6) {
                toast.info("Please fill all the fields!");
            } else {
                setloader1(true)
              axios
              .post("http://internalhrapi.aiksol.com/api/newchangereqdata", userdata)
              .then( (response) => {
                  setloader1(false)
    setShowForm(false)
                  setuserdata({});
    
            }).catch((err) => {
                  console.log(err);
                });
            }
          };
    const getProject=()=>{
        axios
              .get(`http://internalhrapi.aiksol.com/api/requestpopulate`)
              .then(function (response) {
                setAllProjects(response.data.Projects)
              })
              .catch((err) => {
                console.log(err);
    
              });
          };
    
    useEffect(()=>{
        getProject()
    },[])

  return (
    <>
      <ToastContainer autoClose={3000}/>
    <div className="SettingContainer1 mt-3 Sitka height90" >
      <div className="col-md-12 col-lg-12 col-xl-12 " style={{height:"270px"}}>
        <div className="col-12 pt-2">
          <div>ChangeInRequest Form</div>
          <hr></hr>
        </div>
        <div className="row mt-3">
          <div className="col-2 text-right">
            <span className="txt"> Description</span>
          </div>
          <div className="col-4  text-left">
            <span>
            
              <input
               name='description'
                type="text"
                value={userdata.description} 
                onChange={objectFormation}
                id="Description"
                autoComplete="off"
                className="input_border formInput "
              ></input>
            </span>
          </div>
          <div className="col-2  text-right">
            <span className="txt">Priority-Cell</span>
          </div>
          <div className="col-4  text-left">
            <span>
              {" "}
              <select  className="input_border formInput " id="Priority" name='priority' value={userdata.priority} onChange={objectFormation}>
            <option value="">select </option>
            <option value="Medium">Medium</option>
            <option value="Critical">Critical</option>
            <option value="High">High</option>
            <option value="Low">Low</option>
          </select>
            </span>
          </div>
          <div className="col-2 mt-3 text-right">
            <span className="txt">Google-Docs</span>
          </div>
          <div className="col-4 mt-3 text-left">
            <span>
              {" "}
              <input
                name='google_docs' value={userdata.google_docs} onChange={objectFormation}
                id="GoogleDocs"
                type="text"
                className="input_border formInput "
              ></input>
            </span>
          </div>
          <div className="col-2 mt-3 text-right">
            <span className="txt">Project-Name</span>
          </div>
          <div className="col-4 mt-3 text-left">
            <span>
              {" "}
              <select name='project' id="ProjectName"  className="input_border formInput " value={userdata.project} onChange={(e)=>{
const filterModule=allProjects?.find((projct)=>{
return projct.project_id===e.target.value
})
setModule(filterModule.module)
userdata.project=e.target.value
          }} >
            <option value="">select </option>
            {allProjects&&allProjects?.map((project)=>(

            <option key={project?.project_id} value={project?.project_id}>{project?.project_name}</option>
            ))}
   
          </select>
            </span>
          </div>
          <div className="col-2 mt-3 text-right">
            <span className="txt">Project-Module</span>
          </div>
          <div className="col-4 mt-3 text-left">
            <span>
              {" "}
              <select name='module' id="Module"  className="input_border formInput " value={userdata.module} onChange={(evt)=>{
            const SubModuleFilter = Module?.find((mode) => {

                return mode.module_id === evt.target.value;
              });
              setProjectSubModule(SubModuleFilter.submodule);
              userdata.module=evt.target.value

          }} >
            <option value="">select </option>
            {
                Module?.map((mod)=>(
                    
                    <option key={mod?.module_id} value={mod?.module_id}>{mod?.module_name}</option>
                ))
            }
   
          </select>
            </span>
          </div>
          <div className="col-2 mt-3 text-right">
            <span className="txt">Project-SubModule</span>
          </div>
          <div className="col-4 mt-3 text-left">
            <span>
              {" "}
              <select name='submodule' id="SubModule"  className="input_border formInput "  value={userdata.submodule} onChange={objectFormation}  >
            <option value="">select </option>
            {ProjectSubModule?.map((submod) => (
                
            <option  key={submod?.submodule_id} value={submod?.submodule_id}>{submod?.submodule_name}</option>
                    ))}
         
          </select>
            </span>
          </div>

          <div className="col-6 mt-3 ">{/* empty */}</div>
          <div className="col-12 mt-3  text-right">
            <span className="float-right ml-1">
              <button
                onClick={() => {
                    setShowForm(false);
                }}
                className="tabletopbutton   text-right "
              >
                Cancel
              </button>
            </span>

            <span className="float-right ml-1">
              <button
                id="buyerbtn"
                onClick={addUserData}
                className="tabletopbutton   text-right  mr-2"
              >
                Save
              </button>
            </span>
            <div className="loaderclass ml-1 float-right">
              {" "}
              {loader1 && (
                <Loader
                  type="Oval"
                  color="black"
                  height={30}
                  width={30}
                  radius={12}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default ChangeinReqForm