import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getAllExpenceTags } from "../../actions/ExpenceTagAction";

const ExpenceTagTable = () => {
  const [AllExpenceTags, setAllExpenceTags] = useState([]);
  const dispatch = useDispatch();
  const { ExpenceTags, loading } = useSelector(
    (state) => state?.ExpenceTagReducer
  );

  const [AllFilteredExpenceTags, setAllFilteredExpenceTags] = useState([]);

  useEffect(() => {
    dispatch(getAllExpenceTags());
  }, [dispatch]);

  useEffect(() => {
    setAllExpenceTags(ExpenceTags);
    setAllFilteredExpenceTags(ExpenceTags);
  }, [ExpenceTags]);

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setAllFilteredExpenceTags(AllExpenceTags);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = AllExpenceTags.sort().filter((v) =>
          regex.test(v?.TagName)
        );
      }

      setAllFilteredExpenceTags(suggestions);
    }
  };
  return (
    <div className="SettingContainer1  Sitka ">
      <div className="col-md-12 col-lg-12 col-xl-12" style={{ height: "70vh" }}>
        <div className="row row1_style input_border ">
          <div className="col-4">
            <span>
              {" "}
              <input
                type="text"
                onChange={onTextChange}
                autoComplete="off"
                placeholder="Search By Tag Name"
                className="input_border"
              />
            </span>

            <span className=" mr-1 float-right">
              <div className="loaderclass ml-1">
                {" "}
                {loading && (
                  <Loader
                    type="Oval"
                    color="black"
                    height={30}
                    width={30}
                    radius={12}
                  />
                )}
              </div>
            </span>
          </div>
        </div>
        <div className="row">
          <table class="table tableCash table-hover">
            <thead class="purple whiteText">
              <tr>
                <th className="th1" scope="col">
                  Sr
                </th>
                <th className="th1" scope="col">
                  Parent
                </th>
                <th className="th1" scope="col">
                  Expense Tag Name
                </th>
              </tr>
            </thead>
            <tbody>
              {AllFilteredExpenceTags?.map((value, i) => (
                <tr>
                  <th className="th2" scope="row">
                    {i + 1}
                  </th>
                  <td className="th2">{value?.ParentTag?.TagName}</td>

                  <td className="th2">{value?.TagName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ExpenceTagTable;
