const getallpopulatedevcostdata = {data: [], loading: true};

export const GetAllPopulateDevCostDataReducer = (state = getallpopulatedevcostdata, action) =>{
    switch(action.type){
        case 'getallpopulatedevcostdata':
            return{
                data:action.payload,
                loading:action.loader
            }
        default:
            return state;
    }
}