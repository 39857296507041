import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Loader from 'react-loader-spinner'

const ChangeInClientStatus=({setShowForm,setShowupdateForm,filterCientStatus,edit,setedit,getdata})=> {
    const [loader1, setloader1] = useState(false);
    const date=new Date()
    // const [isloader,setIsloader]=useState(false)

    const [isDisabled,setIsDisabled]=useState(false)
    // const [disabledRejection,setDisabledRejection]=useState(false)
    const [clientData,setClientData]=useState({
        client_status:filterCientStatus?filterCientStatus.client_status:"",
        approval_date:date.toISOString().split('T')[0],
        rejection_date:date.toISOString().split('T')[0],
        amend_status:"pending",
        description:"",

    })
    let ret=false; 
    const handleChange = (e) => {
        if(e.target.value==="rejected"){
    document.getElementById("modalAddnewJournal").style.display = "block";

        }
        setClientData({
            ...clientData,
            [e.target.name]:e.target.value,
        });
      };

      useEffect(()=>{
        if(filterCientStatus?.client_status==="approved"){
            setIsDisabled(true)
        }
      },[])
      const [statusUpdate]=useState([
        {
            statusValue:"Fresh",
            statusName:"Fresh",

        },
        {
            statusValue:"approved",
            statusName:"approved",

        },
        {
            statusValue:"rejected",
            statusName:"rejected",

        },
    ])
    const addClientStatus=()=>{
        setloader1(true)

        
            axios
            .patch("http://internalhrapi.aiksol.com/api/clientstatus",{request_id:filterCientStatus.request_id,client_status:clientData.client_status,approval_date:clientData.approval_date,timeline_id:filterCientStatus.req_timeline } )
            .then( (response) => {
                setloader1(false)
                getdata()
                setShowupdateForm("Table")
                
          }).catch((err) => {
                setloader1(false)

              });
          
    
}
const addRejection=()=>{
  
    setloader1(true)
       
    axios
        .patch("http://internalhrapi.aiksol.com/api/clientstatus",{request_id:filterCientStatus.request_id,client_status:clientData.client_status,rejection_date:clientData.rejection_date,amend_status:clientData.amend_status,description:clientData.description } )
        .then( (response) => {
            setloader1(false)
            getdata()
            setShowupdateForm("Table")
            // setshowAlertModal(false)



      }).catch((err) => {
            console.log(err);
          });

}



  return (
    <>
     
    {
        edit?(
            <div>
              <div style={{paddingTop:"20px"}} >
        <button  type="button" style={{width:"50px",height:"30px", backgroundColor:"#3480FA", color:"white",border:"none",borderRadius:"5px",}} onClick={()=>{setShowupdateForm("Table");setedit(false)}}>Back</button>
        </div>
        {
        filterCientStatus?.Amendement?.length>0?(
<div className="SettingContainer1 mt-3 Sitk dataTablecsSales height90">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">

        <div className="row" style={{maxHeight:"450px"}}>
        <table class="table table-hover tableCash "  >
              <thead class="purple whiteText">
                <tr>
                <th scope="col" className="th1">
                    Sr.No
                  </th>
                  <th scope="col" className="th1">
                  Date
                  </th>
                  <th scope="col" className="th1">
                  Description
                  </th>
                  <th scope="col" className="th1">
                  Status
                  </th>
                 
                </tr>
              </thead>
              <tbody >
              {filterCientStatus?.Amendement?.map((amendData,ind)=>{
  return (
        <tr>
    <td scope="row">{ind+1}</td>
    <td>{amendData?.rejection_date}</td>
    <td>{amendData?.description}</td>
    <td>{amendData?.amend_status}</td>
  </tr>
)
})}
              </tbody>
            </table>

            </div>

        </div>
        </div>
        ):""
            }
        </div>
        ):(
            <div className="SettingContainer1 mt-3 Sitka height90" >
            <div className="col-md-12 col-lg-12 col-xl-12 " style={{height:"270px",}}>
            <div className="col-12 pt-2">
                <div>ChangeIn Client Form</div>
                <hr></hr>
              </div>
              <div  style={{width:"100%",display:"flex",justifyContent:"center",maxWidth:"800px",paddingTop:"20px"}}>
      
              <div className="col-2 text-right">
                  <span className="txt"> Client-Status</span>
                </div>
                <div className="col-4  text-left">
                  <span>
                  
                  <select style={{width:"300px"}}  name='client_status' value={clientData.client_status} onChange={handleChange}>
                  <option value="">select </option>
                  {statusUpdate?.map((status,ind)=>{
                      if(status.statusValue===clientData.client_status){
      ret=true
                      }
                      if(ret===true){
      
                      }
                      return( <option key={ind} value={status.statusValue}>{status.statusName}</option>)
      
              })}
                </select>
                  </span>
                </div>
            
              </div>
      
       <div >
                     <div id="modalAddnewJournal" class="modalscreen "  >
                       <div class="modal-content-screen1" style={{height:"350px",}}>
                         {/* <div className="row m-0 p-0"> */}
                         <span
                  class="close"
                  onClick={() => {
                    document.getElementById("modalAddnewJournal").style.display =
                      "none";
                  }}
                >
                  &times;
                </span>
                         
                   
              
              <div
                id="inputBox"
                style={{paddingTop:"20px"}}
                // style={{ width: "100%", maxWidth: "540px",paddingBottom:"10px" , padding:"30px"}}
              >
                  <div>
                <label class="form-label">Description</label>
                </div>
                <textarea rows="4" class="form-control" name='description' value={clientData.description} onChange={handleChange} ></textarea>
              </div>
        <div 
        style={{display:"flex",justifyContent:"end",paddingTop:"10px"}}
        
        >
              {loader1 && (
                      <Loader
                        type="Oval"
                        color="black"
                        height={30}
                        width={30}
                        radius={12}
                      />
                    )}
                    
        <div 
        // style={{paddingLeft:"470px",paddingBottom:"15px"}} 
        >
            
      <button type="button" className="tabletopbutton   text-right  mr-2"  onClick={addRejection}>Save</button>
      </div>
      </div>

  
      
            
                           
                         {/* </div> */}
                       </div>
                     </div>
                 
                     </div>
      
      <div style={{width:"100%",display:"flex",justifyContent:"center",maxWidth:"670px",paddingTop:"20px"}} >
                <div className="col-12 mt-3  text-right">
                  <span className="float-right ml-1">
                    <button
                      onClick={() => {
                          setShowupdateForm("Table");
                          setedit(false)
                      }}
                      className="tabletopbutton   text-right "
                    >
                      Cancel
                    </button>
                  </span>
      
                  <span className="float-right ml-1">
                    <button
                      id="buyerbtn"
                      onClick={addClientStatus}
                      className="tabletopbutton   text-right  mr-2"
                    >
                      Save
                    </button>
                  </span>
                  <div className="loaderclass ml-1 float-right">
                    {" "}
                    {loader1 && (
                      <Loader
                        type="Oval"
                        color="black"
                        height={30}
                        width={30}
                        radius={12}
                      />
                    )}
                  </div>
                </div>
                </div>
            </div>
            </div>
        )
    }

    
    </>
  )
}

export default ChangeInClientStatus;