import React, { useState, useEffect } from "react";
import "./haroon.css";
import "../App.css";
import axios from "axios";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { ProjectStoreContext } from "./context/projectdetailstore";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";

const ProjectSelection = () => {
  const { updateOwnProject, updateProjectType } =
    React.useContext(ProjectStoreContext);
    const cookies = new Cookies();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
  let history = useHistory();
  const [project, setproject] = useState([]);
  const [loader1, setloader1] = useState(false);
  useEffect(() => {
    setloader1(true);
    axios({
      method: "get",
      url: window.$url + `/Users/AssignedPorjects`,
      headers: {
        Authorization: `Bearer ${cookies.get("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess) {
          setproject(result.data.ProjectsList);
          setloader1(false);
        } else {
          toast.info("Error in Geting Projecs. Login Again!!");
          setloader1(false);
        }
      },
      (error) => {
        toast.info(error);
        setloader1(false);
        // localStorage.clear();
        //  history.push({
        //    pathname: "/",
        //  });
      }
    );
  }, []);
  const setProjectinfo = (id) => {
    var r = window.confirm(
      "Are you Sure you want to select " + id.Project.ProjectName
    );
    if (r === true) {
      var projectname = document.getElementById(
        "Pname" + id.Project.ProjectId
      ).innerText;
      var projectid = document.getElementById(
        "PId" + id.Project.ProjectId
      ).innerText;
      if (projectid === "") {
        projectid = 0;
      }

      updateOwnProject(id.Project.OwnProject);
      updateProjectType(id.Project.ProjectType);
      cookies.set("ProjectId", projectid, {
        path: "/",
        expires: new Date(tomorrow),
        maxAge: 10000,
      })
      cookies.set("ProjectName", projectname, {
        path: "/",
        expires: new Date(tomorrow),
        maxAge: 10000,
      })
      cookies.set("ProjectRoles", JSON.stringify(id.Role), {
        path: "/",
        expires: new Date(tomorrow),
        maxAge: 10000,
      })
      cookies.set("OwnProject", id.Project.OwnProject, {
        path: "/",
        expires: new Date(tomorrow),
        maxAge: 10000,
      })
      cookies.set("ProjectType", id.Project.ProjectType, {
        path: "/",
        expires: new Date(tomorrow),
        maxAge: 10000,
      })
      // localStorage.setItem("ProjectId", projectid);
      // localStorage.setItem("ProjectName", projectname);
      // localStorage.setItem("ProjectRoles", JSON.stringify(id.Role));

      // localStorage.setItem("OwnProject", id.Project.OwnProject);
      // localStorage.setItem("ProjectType", id.Project.ProjectType);
      history.push({
        pathname: "/Dashboard",
      });
    }
  };
  return (
    <>
      {" "}
      <div className="container p-2 text-center">
        <h1 id="xyx" className="h1css">
          Select the Project you want to work on
        </h1>
        <div className="ml-1 loaderclass">
          {" "}
          {loader1 && (
            <Loader
              type="Oval"
              color="black"
              height={30}
              width={30}
              radius={12}
            />
          )}
        </div>
        <div className="card-list mt-2">
          {project.map((x) => (
            <div
              className="card-container"
              onClick={() => {
                setProjectinfo(x);
              }}
            >
              <h3 id={"Pname" + x.Project.ProjectId}>
                {x.Project.ProjectName}
              </h3>
              <p id={"Pdesc" + x.Project.ProjectId}>{x.Project.projectDesc}</p>
              <p hidden id={"PId" + x.Project.ProjectId}>
                {x.Project.ProjectId}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ProjectSelection;
