import React, { useState } from 'react';
import axios from 'axios';

export const ProjectStoreContext = React.createContext()

const ProjectStoreContextProvider = (props) => {

    const [ownProject ,setOwnProject] = useState(false);
    const [projectType, setProjectType] = useState(null);

    const updateOwnProject = (val) => {
        setOwnProject(val)
    }

    const updateProjectType = (val) => {
        setProjectType(val)
    }

    return (
        <ProjectStoreContext.Provider value={{ownProject, projectType, updateOwnProject, updateProjectType}}>
            {props.children}
        </ProjectStoreContext.Provider>
    )
}

export default ProjectStoreContextProvider;