import axios from "axios";
import React from "react";
import { useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllExpenceTags } from "../../actions/ExpenceTagAction";
import Cookies from "universal-cookie";
const ExpenceTagForm = () => {
  const cookies=new Cookies()
  const [loading, setloading] = useState(false);
  const [ExpenceTagData, setExpenceTagData] = useState({
    TagName: "",
    ExpenseTagsId: 0,
    ParentTagId: "",
  });
  const dispatch = useDispatch();
  const { ExpenceTags } = useSelector((state) => state?.ExpenceTagReducer);
  console.log(ExpenceTags);
  const HandelAddExpenceTag = () => {
    if (!ExpenceTagData?.TagName) {
      toast.error("Please Add Tag Name");
      return;
    }

    setloading(true);
    axios({
      method: "post",
      url: window.$url + "/PaymentVoucher/AddExpenseTag",
      data:
        ExpenceTagData?.ParentTagId === ""
          ? {
              TagName: ExpenceTagData.TagName,
              ProjectId: cookies.get("ProjectId"),
            }
          : {
              TagName: ExpenceTagData.TagName,
              ParentTagId: ExpenceTagData.ParentTagId,
              ProjectId: cookies.get("ProjectId"),
            },
      headers: {
        Authorization: `Bearer ${cookies.get("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (response) => {
        if (response?.data?.IsSuccess) {
          toast.success(response?.data?.Message);
          dispatch(getAllExpenceTags());
          setExpenceTagData({
            TagName: "",
            ParentTagId: "",
          });
        }

        setloading(false);
      },
      (error) => {
        toast.error(error);
        setloading(false);
      }
    );
  };

  const handelChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setExpenceTagData({ ...ExpenceTagData, [name]: value });
  };
  return (
    <div className="SettingContainer1 overflow-auto  mt-3 Sitka">
      <div className="col-md-12 col-lg-12 col-xl-12 ">
        <div className="col-12 pt-2">
          <div>Expense Tag </div>
          <hr></hr>
        </div>
        <div className="row mt-3">
          <div className="col-3 mt-3 ml-3 text-left ">
            <span className="txt mr-3">Select Tag</span>
            <select
              onChange={(e) => {
                setExpenceTagData({
                  ...ExpenceTagData,
                  ParentTagId: e.target.value,
                });
              }}
              value={ExpenceTagData?.ParentTagId}
              style={{ width: "90%" }}
              className="input_border formInput "
              name=""
              id=""
            >
              <option value="">Select</option>
              {ExpenceTags?.map((item, idx) => {
                return (
                  <option key={idx} value={item.ExpenseTagsId}>
                    {item?.ParentTag?.TagName
                      ? `${item.TagName} (${item?.ParentTag?.TagName})`
                      : item.TagName}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-3 mt-3 ml-3 text-left ">
            <span className="txt mr-3">Tag Name</span>{" "}
            <input
              type="text"
              autoComplete="off"
              name="TagName"
              onChange={handelChange}
              value={ExpenceTagData?.TagName}
              style={{ width: "90%" }}
              className="input_border formInput "
            />
          </div>
        </div>
        <div className="row mt-3 ">
          <div className="col-12  text-right ">
            {loading ? (
              <Loader
                type="Oval"
                color="black"
                height={30}
                width={30}
                radius={12}
              />
            ) : cookies.get("ProjectType") === "null" ? null : (
              <span>
                <button
                  id="saveblock"
                  onClick={HandelAddExpenceTag}
                  className="tabletopbutton   text-right mb-2 mr-2"
                >
                  Save
                </button>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenceTagForm;
