import React, { lazy, Suspense, useState, useEffect } from "react";
import "./haroon.css";
import "../App.css";

import { MonthlyAlert } from "../ContextApi/MonthlyAlert";
import { useContext } from "react";
import LazyLoader from "../Components/LazyLoader/LazyLoader";
import ExpenceTagsMain from "./Setting/ExpenceTag/ExpenceTagsMain";
import { ToastContainer } from "react-toastify";
import Cookies from "universal-cookie";
const cookies=new Cookies()
const MonthlyAltertModal = lazy(() =>
  import("../MonthlyAlertModal/AltertModal")
);
const UnitMain = lazy(() => import("./InventoryComponents/Unit/UnitMain"));
const ProjectMain = lazy(() =>
  import("./InventoryComponents/Project/ProjectMain")
);
const BlockPage = lazy(() => import("./InventoryComponents/Block/BlockPage"));

// PRIMARY PRODUCTION BUTTONS COMPONENTS
const Btngroup = ({ active, count, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`button1 ${active ? "button1Clicked" : ""} ${
        count === "Expence Tag"
          ? "blue"
          : count === "Unit"
          ? "red"
          : count === "Block"
          ? "seaGreen"
          : count === "Block Wise Unit"
          ? "blue"
          : count === "Block Summary"
          ? "darkred"
          : count
      }`}
    >
      {count}
    </button>
  );
};

// PRODUCTION PAGE COMPONENT
const Setting = () => {
  const [RoleComponents, setRoleComponents] = useState([]);
  const { changeState, showAlertModal } = useContext(MonthlyAlert);
  const [showAert, setshowAert] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem("isPaid")) {
      changeState(true);
      setshowAert(true);
    } else {
      setshowAert(false);
    }
  }, []);
  useEffect(() => {
    setRoleComponents(cookies.get("ProjectRoles"));
  }, []);
  const [button, setbutton] = useState([]);
  const [chosen2, setChosen2] = useState();
  useEffect(() => {
    let btn = [];

    (RoleComponents?.includes("BLOCK, COMPLETE") ||
      RoleComponents?.includes("BLOCK, PARTIAL")) &&
      btn.push({ button1: "Expence Tag", component: <ExpenceTagsMain /> });

    setChosen2(btn[0]);
    setbutton(btn);
  }, [RoleComponents]);
  return (
    <Suspense fallback={<LazyLoader />}>
      <div>
        {/* MAPPING PRIMARY BUTTONS ON THE PRODUCTION PAGE */}
        {button.map((b) =>
          cookies.get("ProjectId") === null ||
          cookies.get("ProjectId") === "" ? (
            <Btngroup
              key={b.button1}
              count={b.button1}
              active={b.button1 === chosen2.button1}
              onClick={() => {
                if (showAert === true) {
                  changeState(true);
                }
                setChosen2(b);
              }}
            />
          ) : b.button1 === "Project" ? null : (
            <Btngroup
              key={b.button1}
              count={b.button1}
              active={b.button1 === chosen2.button1}
              onClick={() => {
                if (showAert === true) {
                  changeState(true);
                }
                setChosen2(b);
              }}
            />
          )
        )}
      </div>
      <div>
        {button.map((b) =>
          b.button1 === chosen2.button1 ? b.component : null
        )}
      </div>
      <MonthlyAltertModal />
      <ToastContainer />
    </Suspense>
  );
};

export default Setting;
