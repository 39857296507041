const allpopulatecontractsdata = {data: [], loading: true};

export const GetAllPopulateContractsDataReducer = (state = allpopulatecontractsdata, action) =>{
    switch(action.type){
        case 'getallpopulatecontractsdata':
            return{
                data:action.payload,
                loading:action.loader
            }
        default:
            return state;
    }
}