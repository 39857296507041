import React, { lazy, Suspense, useEffect, useState } from "react";
import "./App.css";
import "./Bootstrap/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import menu from "./Images/menu.svg";
import { useHistory } from "react-router-dom";
// import Accounts from "./Pages/Accounts";
import "./Pages/haroon.css";
// import Dashboard from "./Pages/Dashboard";

// import Purchase from "./Pages/Purchase";
// import Sale from "./Pages/Sale";
// import Inventory from "./Pages/Inventory";
// import Vouchers from "./Pages/Vouchers";
import { RiArrowDropDownLine, RiExchangeBoxLine } from "react-icons/ri";
import icon from "../src/Images/icon.png";
import { MdAddShoppingCart, MdDashboard, MdStore } from "react-icons/md";
import { RiFilePaper2Line } from "react-icons/ri";
import { GiBookshelf } from "react-icons/gi";
import { FiTruck } from "react-icons/fi";
import { BsBell } from "react-icons/bs";
import { ProjectStoreContext } from "./Pages/context/projectdetailstore";
import { AppStoreContext } from "./Pages/context/contextstore";
import ChangeRequestForm from "./Pages/ChangeRequestForm/ChangeRequestForm";
import axios from "axios";
import { MonthlyAlert } from "./ContextApi/MonthlyAlert";
import moment from "moment";
import { useContext } from "react";
import Loader from "react-loader-spinner";
import LazyLoader from "./Components/LazyLoader/LazyLoader";
import ChangeInReqMain from "./Pages/ChangeInReqMain";
import { BiLogOut } from "react-icons/bi";
import { AiTwotoneSetting } from "react-icons/ai";
import Setting from "./Pages/Setting";
import Cookies from "universal-cookie";

const Dashboard = lazy(() => import("./Pages/Dashboard"));
const Purchase = lazy(() => import("./Pages/Purchase"));
const Sale = lazy(() => import("./Pages/Sale"));
const Inventory = lazy(() => import("./Pages/Inventory"));
const Vouchers = lazy(() => import("./Pages/Vouchers"));
const Accounts = lazy(() => import("./Pages/Accounts"));
const cookies = new Cookies();

function SideButton(props) {
  return (
    <button
      onClick={props.onClick}
      className={
        props.active
          ? "lightPurple row  sideText sideButton ml-2 mr-1"
          : "row  sideText sideButton ml-2 mr-1"
      }
    >
      {props.icon}
      {props.isOpen ? (
        <p className=" ml-3 sideText whiteText">{props.text}</p>
      ) : null}
    </button>
  );
}
function SideBarOpen() {
  const [RoleComponents, setRoleComponents] = useState([]);
  const [Menu, setMenu] = useState([]);
  const [changeRequest, setchangeRequest] = useState(false);
  useEffect(() => {
    setRoleComponents(
      cookies.get("Token") != undefined ? cookies.get("ProjectRoles") : []
    );
  }, []);
  useEffect(() => {
    setMenu([
      RoleComponents?.includes("DASHBOARD")
        ? {
            text: "Dashboard",
            icon: (
              <MdDashboard
                color="white"
                size="1.3em"
                style={{ marginLeft: "15px", marginTop: "13px" }}
              />
            ),
            component: <Dashboard></Dashboard>,
          }
        : null,
      RoleComponents?.includes("VOUCHERS")
        ? {
            text: "Vouchers",
            icon: (
              <RiFilePaper2Line
                color="white"
                size="1.3em"
                style={{ marginLeft: "15px", marginTop: "12px" }}
              />
            ),
            component: <Vouchers></Vouchers>,
          }
        : null,
      RoleComponents?.includes("ACCOUNTS")
        ? {
            text: "Accounts",
            icon: (
              <GiBookshelf
                color="white"
                size="1.3em"
                style={{ marginLeft: "15px", marginTop: "12px" }}
              />
            ),
            component: <Accounts></Accounts>,
          }
        : null,
      RoleComponents?.includes("SALE")
        ? cookies.get("ProjectType") !== "OTHER"
          ? {
              text: "Sale",
              icon: (
                <FiTruck
                  color="white"
                  size="1.3em"
                  style={{ marginLeft: "18px", marginTop: "12px" }}
                />
              ),
              component: <Sale></Sale>,
            }
          : null
        : null,
      RoleComponents?.includes("PURCHASE")
        ? cookies.get("ProjectType") !== "OTHER"
          ? {
              text: "Purchase",
              icon: (
                <MdAddShoppingCart
                  color="white"
                  size="1.3em"
                  style={{ marginLeft: "15px", marginTop: "12px" }}
                />
              ),
              component: <Purchase />,
            }
          : null
        : null,
      //*inventory button in sdiebar
      RoleComponents?.includes("INVENTORY")
        ? cookies.get("ProjectType") !== "OTHER"
          ? {
              text: "Inventory",
              icon: (
                <MdStore
                  color="white"
                  size="1.3em"
                  style={{ marginLeft: "15px", marginTop: "12px" }}
                />
              ),
              component: <Inventory></Inventory>,
            }
          : null
        : null,
      {
        text: "Change Request",
        icon: (
          <RiExchangeBoxLine
            color="white"
            size="1.3em"
            style={{ marginLeft: "10px", marginTop: "12px" }}
          />
        ),
        component: <ChangeInReqMain></ChangeInReqMain>,
      },
      {
        text: "Setting",
        icon: (
          <AiTwotoneSetting
            color="white"
            size="1.3em"
            style={{ marginLeft: "10px", marginTop: "12px" }}
          />
        ),
        component: <Setting />,
      },
      {
        text: "Log Out",
        icon: (
          <BiLogOut
            color="white"
            size="1.3em"
            style={{ marginLeft: "10px", marginTop: "12px" }}
          />
        ),
        component: null,
      },
    ]);
  }, [RoleComponents]);

  const { ownProject, projectType, updateOwnProject, updateProjectType } =
    React.useContext(ProjectStoreContext);

  // var user = cookies.get("username");
  const [isOpen, setState] = useState(true);
  const handleToggle = () => {
    isOpen ? setState(false) : setState(true);
  };
  const [chosen3, setChosen3] = useState("Dashboard");
  let history = useHistory();

  return (
    <Suspense fallback={<LazyLoader />}>
      <div
        style={{ overflow: "auto", maxHeight: "100%" }}
        className="col-auto m-0 p-0 purple "
      >
        <div className="row m-0 p-0">
          <div className="col">
            <img
              style={{ cursor: "pointer" }}
              onClick={handleToggle}
              className="mt-2 toggle"
              src={menu}
            ></img>
          </div>
        </div>
        <div className="row m-0 p-0">
          <div className="col-auto pt-3">
            {/* <img className='mt-2 ml-3 logo' src={shortLogo}></img><img className='mt-2 ml-1 logo' src={logoName}></img>  */}
            {isOpen ? (
              <h3 className="pl-2">
                <span style={{ color: "white" }}>Rafi</span>
                <span style={{ color: "yellow" }}>Accounting</span>
              </h3>
            ) : null}
          </div>
        </div>
        <div style={{ overflowY: "hidden" }} className="pt-2 purple ">
          {Menu.map(
            (t) =>
              t !== null && (
                <SideButton
                  isOpen={isOpen}
                  text={t.text}
                  icon={t.icon}
                  onClick={() => {
                    if (t.text === "Log Out") {
                      cookies.remove("Token");
                      sessionStorage.clear();
                      history.push("/");
                    } else {
                      setChosen3(t.text);
                    }
                    // setchangeRequest(false);
                  }}
                  active={t.text === chosen3}
                />
              )
          )}
          {isOpen && (
            <span>
              <h5 style={{ color: "white" }} className="loaderclass mt-3 ml-2 ">
                {cookies.get("ProjectName")}
              </h5>
            </span>
          )}
        </div>
      </div>
      <div
        style={{ height: "100%", width: "70%" }}
        className="col p-0  lightGrey "
      >
        {/* <div style={{ height: "7%" }} className="col p-0   lightGrey ">
          <div className="navbar-style purple">
            <span>
              <h5 style={{ color: "white" }} className="loaderclass mt-3 ">
                {cookies.get("ProjectName")}
              </h5>
            </span>
            <span className="ml-4 mt-2 icon2-font">
              {" "}
              <BsBell className="text-white" />
            </span>
            <span className=" ml-4 mt-1 textPurple">
              {" "}
              <div className="col-auto pr-0 pt-1">
                <img className="chatboximage" src={icon} alt="rafi-icon" />
              </div>
            </span>
            <span className=" ml-1 ">
              <Dropdown className="accountBtn ">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="accountBtn"
                >
                  <RiArrowDropDownLine className="drop"></RiArrowDropDownLine>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      localStorage.clear();
                      sessionStorage.clear();
                    }}
                    href="/"
                  >
                    LogOut
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setchangeRequest(true);
                    }}
                  >
                    Request Form
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </div>
        </div> */}
        <div style={{ height: "100%" }} className="col lightGrey ">
          {Menu.map(
            (t) => t !== null && (t.text === chosen3 ? t.component : null)
          )}
        </div>
      </div>
    </Suspense>
  );
}
function App() {
  const { changeState } = useContext(MonthlyAlert);
  // useEffect(() => {
  //   // if (!sessionStorage.getItem("isPaid")) {
  //   axios
  //     .get("https://sheetdb.io/api/v1/kh4dbddtxuibs")
  //     // .get("https://sheetdb.io/api/v1/45ajzrr13r12b")
  //     .then((res) => {
  //       if (
  //         res?.data?.length > 0 &&
  //         res.data[0]?.IsPaid === "TRUE" &&
  //         Number(moment().format("DD")) > Number(res.data[0]?.Date)
  //       ) {
  //         sessionStorage.setItem("isPaid", true);
  //         changeState(true);
  //       } else {
  //         changeState(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   // }
  // }, [sessionStorage.getItem("isPaid")]);
  const [RoleComponents, setRoleComponents] = useState([]);

  let history = useHistory();
  const { ownProject, projectType, updateOwnProject, updateProjectType } =
    React.useContext(ProjectStoreContext);
  const { updateProjectId } = React.useContext(AppStoreContext);

  React.useEffect(() => {
    updateOwnProject(cookies?.get("OwnProject"));
    updateProjectType(cookies?.get("ProjectType"));
    updateProjectId(cookies?.get("ProjectId"));
    setRoleComponents(
      cookies?.get("ProjectRoles") != undefined
        ? cookies.get("ProjectRoles")
        : []
    );
  }, []);
  // console.log(cookies.get("Token"));

  React.useEffect(() => {
    if (!cookies?.get("Token")) {
      history.push({
        pathname: "/",
      });
    }
    return;
  }, []);

  return (
    <div className="row m-0 w-100 fullHeight">
      <SideBarOpen />
    </div>
  );
}
export default App;
