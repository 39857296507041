import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react';
import ChangeinReqForm from './ChangeRequestForm/ChangeinReqForm';
import ChangeInReqTable from './ChangeRequestForm/ChangeInReqTable';

const ChangeInReqMain=()=> {
    const [ShowForm,setShowForm]=useState(false)

    return (
      <div>
  
  {
      ShowForm?(
  <ChangeinReqForm setShowForm={setShowForm}/>
      ):(
  <ChangeInReqTable setShowForm={setShowForm}/>
      )
  }
  
      </div>
    )
}

export default ChangeInReqMain;
ChangeInReqMain.propTypes={
    setShowForm:PropTypes.any,
}